.splash-line {
  animation: fadeIn 1s ease-in-out forwards, colorChange 10s infinite;
  opacity: 0;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

@keyframes colorChange {
  0% { background-color: #38bdf8; }
  25% { background-color: #38bdf8; }
  50% { background-color: #7dd3fc; }
  75% { background-color: #e0f2fe; }
  100% { background-color: #e0f2fe; }
}

